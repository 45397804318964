import React, { useState } from 'react';
import './Header.css';

import SideDrawer from './components/SideDrawer/SideDrawer';
import Backdrop from '../UIElements/Backdrop';
import NavegationLinks from './components/NavegationLinks/NavegationLinks';
import Header from './components/Header/Header';

//assets
import saversClubIcon from '../../../utils/assets/logo/savers-club-icon.png';
import facebookIcon from '../../../utils/assets/header/facebook-icon.png';
import instagramIcon from '../../../utils/assets/header/instagram-icon.png';

const MainNavigation = props => {
  const [drawerIsOpen, setDrawerIsOpen] = useState(false);

  const openDrawerHandler = () => {
    setDrawerIsOpen(true);
  };

  const closeDrawerHandler = () => {
    setDrawerIsOpen(false);
  };

  const handleSetScreenPosition = () =>{
    window.scroll(0,0);
  }

  return (
    <React.Fragment>
      {drawerIsOpen && <Backdrop onClick={closeDrawerHandler} />}
      <SideDrawer show={drawerIsOpen} onClick={closeDrawerHandler}>
        <nav className="main-navigation__drawer-nav">
          <NavegationLinks />
        </nav>
      </SideDrawer>

      <Header>
        <button
          className="main-navigation__menu-btn"
          onClick={openDrawerHandler}
        >
          <span />
          <span />
          <span />
        </button>
        <div className="main-navigation__logo-container">
          <img src={saversClubIcon} onClick={handleSetScreenPosition} alt='savers-club-icon' className='Navigation__logo'></img>
        </div>
        <nav className="main-navigation__header-nav">
          <NavegationLinks />
        </nav>
        <div className='MainNavigation__main-social-media-container'>
          <div className='MainNavigation__inner-social-media-container'>
            <a href='https://www.facebook.com/Saversclubllc/' target='_blank'><img src={facebookIcon} alt='instagram-icon' className='Header__social-logo'></img></a>
            <a href='https://www.instagram.com/saversclub_/' target='_blank'><img src={instagramIcon} alt='facebook-icon' className='Header__social-logo'></img></a>
          </div>
        </div>


      </Header>
    </React.Fragment>
  );
};

export default MainNavigation;
