import React, { useEffect } from "react";
import WelcomeSection from "../../shared/components/WelcomeSection/WelcomeSection";
import HotItem from "../components/HotItem/HotItem";
import AssociatedBrand from '../components/AssociatedBrand/AssociatedBrand';
import SectionDivider from "../components/SectionDivider/SectionDivider";
import SubscriptionPlan from "../components/SubscriptionPlan/SubscriptionPlan";


import desktopBuyMoreBanerImg from '../../utils/assets/home/buy-more-and-better-jouin-us.png';
import mobileBuyMoreBanerImg from '../../utils/assets/home/mobile-buy-more-and-better-jouin-us.png';
import tabletBuyMoreBanerImg from '../../utils/assets/home/tablet-buy-more-and-better-jouin-us.png';
import desktopSaveWhenShoppingBannerImg from '../../utils/assets/home/save-when-shopping.png';
import tabletSaveWhenShoppingBannerImg from '../../utils/assets/home/tablet-save-when-shopping.png';
import mobileSaveWhenShoppingBannerImg from '../../utils/assets/home/mobile-save-when-shopping.png';

import shoppigBagsIcon from '../../utils/assets/home/icon/shopping-bags-icon.png';
import shippingIcon from '../../utils/assets/home/icon/shipping-icon.png';
import saleIcon from '../../utils/assets/home/icon/sale-icon.png';
import infinitIcon from '../../utils/assets/home/icon/infinit-icon.png';
import platformIcon from '../../utils/assets/home/icon/plataforms-icon.png';
import assistenceIcon from '../../utils/assets/home/icon/assistance-icon.png';



const Home = props => {

    useEffect(()=>{
        window.scroll(0,0);
      },[]);
      
     const bannerSectionInfo = [
         {
             cardTitle : "Buy more and better",
             cardBodyText: `Our Savings Club program gives you premium discounts on purchases that can save you hundreds of dollars!`,
             cardButtonText : "Register",
             cardBannerPosition : 1,
             desktopBackground : desktopBuyMoreBanerImg,
             tabletBackground : tabletBuyMoreBanerImg,
             mobileBackground : mobileBuyMoreBanerImg

         },
         {
            cardTitle : "Save when shopping, dining, traveling and more!",
            cardBodyText: `Our Rewards Program offers a range of exclusive benefits and services. Start saving today by subscribing to our monthly membership.`,
            cardBannerPosition : 2, 
            cardButtonText : "KNOW MORE",
            desktopBackground : desktopSaveWhenShoppingBannerImg,
            tabletBackground : tabletSaveWhenShoppingBannerImg,
            mobileBackground : mobileSaveWhenShoppingBannerImg

        }
     ]

     const dividerInfo = [
        {
            sectionName : "",
            icon1 : shoppigBagsIcon,
            description1 : "FREE GIVEAWAYS",
            icon2: shippingIcon,
            description2 : "FREE SHIPPING",
            icon3 : saleIcon,
            description3 : "DAILY DEALS",
            icon4 : infinitIcon,
            description4 : "DOES NOT EXPIRE",
            icon5 : platformIcon,
            description5:"CROSS-PLATFORM",
            icon6 : assistenceIcon,
            description6 : "24 / 7 / 365 ASSISTANCE"

        }
     ]

     const subscriptionCardInformation = [
        {
            subscriptionType : "TRY",
            subscriptionPrice: "3.49",
            subscriptionTrialInfo : "Trial for 21 days",
            subscriptionTrialPrice: "",
            subscriptionButtonText: "TRY NOW"
        },
        {
            subscriptionType : "MONTH",
            subscriptionPrice: "41.27",
            subscriptionTrialInfo: "Monthly",
            subscriptionTrialPrice: "",
            subscriptionButtonText: "Buy"
        }
        ]

    return(
        <React.Fragment>
            <WelcomeSection props={bannerSectionInfo[0]}/>
            <HotItem/>
            <SectionDivider props={dividerInfo[0]}/>
            <WelcomeSection props={bannerSectionInfo[1]}/>
            <SubscriptionPlan props={subscriptionCardInformation}/>
            <AssociatedBrand/>
        </React.Fragment>
    );
};

export default Home;