import React from "react";

import './AssociatedBrand.css';


import wallDisneyIcon from '../../../utils/assets/home/walt-disney.png';
import  amazonIcon   from '../../../utils/assets/home/amazon.png';
import samsungIcon   from '../../../utils/assets/home/samsung.png';
import firestoneIcon  from '../../../utils/assets/home/firestone.png';
import universalStudioIcon from '../../../utils/assets/home/universal-studios.png';

const AssociatedBrand = () => {

    return (


        <section id="associatedBrands" className="mt-5 mb-5 AssociatedBrands__main-container">
            <div className="AssociatedBrand__main-title-container">
                <h1 className="center AssociatedBrand__main-title pt-4 pb-4 pr-2 pe-2">ASSOCIATED BRANDS</h1>
            </div>
            <div className="AssociatedBrand__icons-contianer pr-2 pe-2">
                <div className="AssociatedBrand__brand-one">
                    <img src={wallDisneyIcon} alt="wallDisneyIcon" className="AssociatedBrand__icon"/>
                </div>
                <div className="AssociatedBrand__brand-two">
                    <img src={amazonIcon} alt="amazonIcon" className="AssociatedBrand__icon" />
                </div>
                <div className="AssociatedBrand__brand-three">
                    <img src={samsungIcon} alt="samsungIcon" className="AssociatedBrand__icon"/>
                </div>
                <div className="AssociatedBrand__brand-four">
                    <img src={firestoneIcon} alt="firestoneIcon" className="AssociatedBrand__icon"/>
                </div>
                <div className="AssociatedBrand__brand-five">
                    <img src={universalStudioIcon} alt="universalStudioIcon"className="AssociatedBrand__icon" />
                </div>
            </div>
        </section>



    );
};

export default AssociatedBrand;