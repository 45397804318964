import React, { useEffect } from "react";

import contactUsBannerImg from '../../utils/assets/contact/contact-us.png';
import WelcomeSection from "../../shared/components/WelcomeSection/WelcomeSection";
import NavegationInfo from "../../shared/components/NavegationInfo/NavegationInfo";
import FormLogin from '../components/LoginForm/FormLogin';




const Login = props => {
    useEffect(()=>{
        window.scroll(0,0);
      },[])
    const bannerSectionInfo =
    {
       cardTitle: "Thinking of joining?",
       cardButtonText: "",
       lengendBackground: contactUsBannerImg
    }

    return (
        <React.Fragment>
            {/* <NavegationInfo page="Login"/> */}
            <FormLogin />
        </React.Fragment>
    );
};

export default Login;