import React, {useEffect}from "react";

import WelcomeSection from '../../shared/components/WelcomeSection/WelcomeSection';
import NavegationInfo from '../../shared/components/NavegationInfo/NavegationInfo';
import ProductDescription from '../../shared/components/ProductDescription/ProductDescription';
import SectionInfo from '../../shared/components/SectionInfo/SectionInfo';
import Benefits from "../components/Benefits/Benefits";
import Faq from '../components/Faq/Faq';

import desktopAboutUsBannerImg from '../../utils/assets/aboutUs/know-about-us.png';
import tabletAboutUsBannerImg from '../../utils/assets/aboutUs/tablet-about-us.png';
import mobileAboutUsBannerImg from '../../utils/assets/aboutUs/mobile-about-us.png';
import coupelShopping from '../../utils/assets/aboutUs/coupel-shopping.png';


const AboutUs = props => {

    useEffect(()=>{
      window.scroll(0,0);
    },[])
    const bannerSectionInfo =
     {
         cardTitle: "What is our identity?",
         cardButtonText: "Join us",
         desktopBackground : desktopAboutUsBannerImg,
         tabletBackground : tabletAboutUsBannerImg,
         mobileBackground : mobileAboutUsBannerImg

     }

     const productDescription = {
        title : "How does it work?",
        img : coupelShopping

     }
          
    return(
        <React.Fragment>
           <WelcomeSection props={bannerSectionInfo}/>
           <NavegationInfo page="About us"/>
           <ProductDescription props={productDescription} />
           <SectionInfo title = "Benefits" color="#FFDBE1"/>
           <Benefits/>
           <Faq/> 
        </React.Fragment>
    );
};

export default AboutUs;