import React, { useEffect } from "react";
import refundPolicyLegen from '../../utils/assets/refundPolicy/problems-with-an-item-return-policy.png';
import ReimbursementGuidelines from '../components/ReimbursementGuidelines/ReimbursementGuidelines';
import WelcomeSection from '../../shared/components/WelcomeSection/WelcomeSection';






const RefundPolicy = props => {
    useEffect(()=>{
        window.scroll(0,0);
      },[])
    const legendInfo =
    {
        cardTitle: "Problems with an item?",
        cardBodyText: `If you are returning a product due to an error or it is not what you wanted, follow the steps to request your refund. It is a quick and easy process!`,
        cardButtonText: "Call us",
        lengendBackground: refundPolicyLegen
    }

    return (

        <React.Fragment>
         
            <WelcomeSection props={legendInfo}/>
            <ReimbursementGuidelines />
            
        </React.Fragment>
    );
};

export default RefundPolicy;