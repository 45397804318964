import React, { useEffect } from "react";


import WelcomeSection from '../../shared/components/WelcomeSection/WelcomeSection';
import NavegationInfo from '../../shared/components/NavegationInfo/NavegationInfo';


import desktopDiscoverOurServiceImg from '../../utils/assets/price/discover-our-services.png';
import tabletDiscoverOurServiceImg from '../../utils/assets/price/tablet-discover-our-services.png'
import mobileDiscoverOurServiceImg from '../../utils/assets/price/mobile-discover-our-services.png'
import AvailablePlan from "../components/AvailablePlan/AvailablePlan";


const Price = props => {
    useEffect(()=>{
        window.scroll(0,0);
      },[])
     const bannerSectionInfo =
     {
        cardTitle: "Discover our services",
        cardBodyText: `Enter a world of countless benefits and unlimited possibilities. Do it now and get your membership!`,
        cardButtonText: "Try Now",
        desktopBackground : desktopDiscoverOurServiceImg,
        tabletBackground : tabletDiscoverOurServiceImg,
        mobileBackground : mobileDiscoverOurServiceImg
     }

    return (
        <React.Fragment>
             <WelcomeSection props={bannerSectionInfo}/>
             <NavegationInfo page="price"/>
             <AvailablePlan/> 
        </React.Fragment>
    );
};

export default Price;