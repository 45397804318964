import React from "react";
import './SubscriptionPlan.css';

import ContactCard from '../../../shared/components/ContactCard/ContactCard';
import SubscriptionCard from '../../../shared/components/SubscriptionCard/SubscriptionCard';


const SubscriptionPlan = ({props}) => {

    

    return(
       <section className="SubscriptionPlan__main-section-container">
            <div className="SubscriptionPlan__title-container">
                <h1 className="SubscriptionPlan__title">Join our community</h1>
            </div>
            <div className="SubscriptionPlan__subscription-plans-container">
                   {
                        props.map((product, index) => (
                            <SubscriptionCard key={index} props={product} />
                        ))
                    }

            </div>
            <div className="SubscriptionPlan__customer-service-container">
                <ContactCard/>
            </div>
       </section>
    );
};

export default SubscriptionPlan;