import React, { useEffect } from 'react';
import desktopCheckoutBannerImg from '../../utils/assets/checkout/checkout.png';
import tabletCheckoutBannerImg from '../../utils/assets/checkout/tablet-check-out.png';
import mobileCheckoutBannerImg from '../../utils/assets/checkout/mobile-check-out.png';

import WelcomeSection from '../../shared/components/WelcomeSection/WelcomeSection';
import NavegationInfo from '../../shared/components/NavegationInfo/NavegationInfo';
import PaymentInfo from '../components/PaymentInfo/PaymentInfo';

const Checkout = () => {

    useEffect(()=>{
        window.scroll(0,0);
      },[]);

    const bannerSectionInfo =
    {
       cardTitle: "We'd love to have you",
       cardButtonText: "",
       desktopBackground : desktopCheckoutBannerImg,
        tabletBackground : tabletCheckoutBannerImg,
        mobileBackground : mobileCheckoutBannerImg
    }

    return (
        <React.Fragment>
           <WelcomeSection props={bannerSectionInfo}/>
           <NavegationInfo page="Checkout"/>
           <PaymentInfo/>
        </React.Fragment>
    );
};

export default Checkout;
