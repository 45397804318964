import React from "react";

import './CallUsButton.css';

const CallUsButton = props => {
    const cellphoneNumber = "8884474914";
    const handleButtonClick = () => {
        window.location.href = `tel:${cellphoneNumber}`;
      };
    return(
   
        <button className="call-us-button" onClick={handleButtonClick} style={{fontSize: '1.1rem', color : '#9741c7', fontWeight: '700'}}>
           {props.phonenumber}
        </button>

    );
};

export default CallUsButton;