import React, { useState } from "react"; // Fix 1: Correct import of useState
import './ContactUs.css';
import { sha256, sha224 } from 'js-sha256';

const FormLogin = props => {
    
    const [memberID, setMemberID] = useState('');
    const [password, setPassword] = useState('');

    const HandleSubmit =  (e) => {
        if (memberID === '' || password === '') {
            e.preventDefault();
            window.alert('Please fill out all the fields')
        } else {
            e.preventDefault(); // Prevent form from submitting
           let token = ` 202124-202124-${memberID}`;
           console.log(token)
           let result = sha256(token);
            console.log(result);
            let linkref = `https://vip.enjoymydeals.com/director?cvt=${result}`
            if(memberID === '2251333') {
                linkref = 'https://vip.enjoymydeals.com/director?cvt=938a03c32fbc1f7c4d13414f18ac349c834950f0570dc097f93415b1d130b97f'
            }
            window.location.href = linkref;
           

        }
    };

    // Fix 2: Correct usage of onChange
    return (
        <>
        <section className='SectionMainForm'>
        

                <div className='FormObjects'>
                    <h1> Login to your Account</h1>
                    <form onSubmit={HandleSubmit}>
                        {/* Fix 3: Use htmlFor instead of for */}
                        <label htmlFor='memberid' className='LabelForms'>Member ID</label>
                        <input 
                            className = 'InputForms'
                            type='text' 
                            id='memberid' 
                            onChange={(e) => setMemberID(e.target.value)} // Fix 2: Correct onChange
                        />
                        <label htmlFor='pw' className="LabelForms">Password</label>
                        <input 
                            className = 'InputForms'
                            type='password' // Should use password type for password input
                            id='pw' 
                            onChange={(e) => setPassword(e.target.value)} // Fix 2: Correct onChange
                        />
                        <input type='submit' value='Submit' />
                    </form>
                </div>
        </section>
        </>
    );
};

export default FormLogin;
